import request from '@/utils/request';

export function insurancefundList(param) {
  return request({
    url: '/insurancefund/city/list',
    method: 'get',
    params: param,
  });
}

export function insurancefundSave(data) {
  return request({
    url: '/insurancefund/city/save',
    method: 'post',
    data,
  });
}

export function insurancefundUpdate(data) {
  return request({
    url: '/insurancefund/city/update',
    method: 'post',
    data,
  });
}

export function insurancefundDelete(data) {
  return request({
    url: '/insurancefund/city/delete',
    method: 'post',
    data,
  });
}

export function insurancefundInfo(id) {
  return request({
    url: `/insurancefund/city/info/${id}`,
    method: 'get',
  });
}

// POST /insurancefund/insurance/delete
//   删除社保模板
//
// GET /insurancefund/insurance/info/{id}
// 根据Id获得社保模板信息
//
// GET /insurancefund/insurance/list
// 获得社保模板列表
//
// POST /insurancefund/insurance/save
// 保存社保模板
//
// POST /insurancefund/insurance/update
// 修改社保模板

export function insurancefundInsuranceDelete(data) {
  return request({
    url: '/insurancefund/insurance/delete',
    method: 'post',
    data,
  });
}

export function insurancefundInsuranceInfo(id) {
  return request({
    url: `/insurancefund/insurance/info/${id}`,
    method: 'get',
  });
}

export function insurancefundInsuranceList(param) {
  return request({
    url: '/insurancefund/insurance/list',
    method: 'get',
    params: param,
  });
}

export function insurancefundInsuranceSave(data) {
  return request({
    url: '/insurancefund/insurance/save',
    method: 'post',
    data
  });
}

export function insurancefundInsuranceUpdate(data) {
  return request({
    url: '/insurancefund/insurance/update',
    method: 'post',
    data
  });
}

// POST /insurancefund/fund/delete
//   删除公积金模板
//
// GET /insurancefund/fund/info/{id}
// 根据Id获得公积金模板信息
//
// GET /insurancefund/fund/list
// 获得公积金模板列表
//
// POST /insurancefund/fund/save
// 保存公积金模板
//
// POST /insurancefund/fund/update
// 修改公积金模板

export function insurancefundFundDelete(data) {
  return request({
    url: '/insurancefund/fund/delete',
    method: 'post',
    data,
  });
}

export function insurancefundFundInfo(id) {
  return request({
    url: `/insurancefund/fund/info/${id}`,
    method: 'get',
  });
}

export function insurancefundFundList(param) {
  return request({
    url: '/insurancefund/fund/list',
    method: 'get',
    params: param,
  });
}

export function insurancefundFundSave(data) {
  return request({
    url: '/insurancefund/fund/save',
    method: 'post',
    data
  });
}

export function insurancefundFundUpdate(data) {
  return request({
    url: '/insurancefund/fund/update',
    method: 'post',
    data
  });
}
