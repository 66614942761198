<template>
  <div class="app-container online-info-page">
    <!-- 添加或修改会员对话框 -->
    <!-- 添加或修改招聘职位对话框 -->
    <el-form ref="form" :model="form" size="small" :rules="rules" label-width="150px">
      <el-form-item label="城市区域" prop="cityRegion">
        <el-select class="input-750" v-model="form.cityRegion" placeholder="">
          <el-option v-for="item in cityList" :label="item.name" :value="item.key" />
        </el-select>
      </el-form-item>

      <el-form-item label="社保差" prop="diffFlag">
        <el-select class="input-750" v-model="form.diffFlag" placeholder="">
          <el-option label="有社保差" :value="1" />
          <el-option label="无社保差" :value="2" />
        </el-select>
      </el-form-item>

      <el-form-item label="大额医疗缴纳类型" prop="largeMedicalPayType">
        <el-select class="input-750" v-model="form.largeMedicalPayType" placeholder="">
          <el-option label="按月缴费" :value="1" />
          <el-option label="按年缴费" :value="2" />
        </el-select>
      </el-form-item>

      <el-form-item label="每月缴费截止日期" prop="payEndDate">
        <el-input class="input-750" v-model="form.payEndDate" placeholder="示例:15" />
      </el-form-item>
    </el-form>

    <table>
      <tbody>
        <tr>
          <td class="text-center bold" colspan="3">社保</td>
          <td class="text-center bold" colspan="3">公积金</td>
        </tr>

        <tr>
          <td class="text-center">类型</td>
          <td class="text-center">截止日期</td>
          <td class="text-center">生效方式</td>
          <td class="text-center">类型</td>
          <td class="text-center">截止日期</td>
          <td class="text-center">生效方式</td>
        </tr>

        <tr>
          <td>新参</td>
          <td>
            <el-input v-model="form.insuranceEnterEndDate" placeholder="截止日期。示例:15" />
          </td>

          <td>
            <el-select v-model="form.insuranceEnterValidType" placeholder="生效方式">
              <el-option label="当前生效" :value="1" />
              <el-option label="次月生效" :value="2" />
            </el-select>
          </td>

          <td>新参</td>
          <td>
            <el-input v-model="form.fundEnterEndDate" placeholder="截止日期。示例:15" />
          </td>

          <td>
            <el-select v-model="form.fundEnterValidType" placeholder="生效方式">
              <el-option label="当前生效" :value="1" />
              <el-option label="次月生效" :value="2" />
            </el-select>
          </td>
        </tr>

        <tr>
          <td>减员</td>
          <td>
            <el-input v-model="form.insuranceLeaveEndDate" placeholder="截止日期。示例:15" />
          </td>

          <td>
            <el-select v-model="form.insuranceLeaveValidType" placeholder="生效方式">
              <el-option label="当前生效" :value="1" />
              <el-option label="次月生效" :value="2" />
            </el-select>
          </td>

          <td>减员</td>

          <td>
            <el-input v-model="form.fundLeaveEndDate" placeholder="截止日期。示例:15" />
          </td>

          <td>
            <el-select v-model="form.fundLeaveValidType" placeholder="生效方式">
              <el-option label="当前生效" :value="1" />
              <el-option label="次月生效" :value="2" />
            </el-select>
          </td>
        </tr>

        <tr>
          <td>转入</td>
          <td>
            <el-input v-model="form.insuranceChangeEnterEndDate" placeholder="截止日期。示例:15" />
          </td>
          <td>
            <el-select v-model="form.insuranceChangeEnterValidType" placeholder="生效方式">
              <el-option label="当前生效" :value="1" />
              <el-option label="次月生效" :value="2" />
            </el-select>
          </td>
          <td>转入</td>
          <td>
            <el-input v-model="form.fundChangeEnterEndDate" placeholder="截止日期。示例:15" />
          </td>
          <td>
            <el-select v-model="form.fundChangeEnterValidType" placeholder="生效方式">
              <el-option label="当前生效" :value="1" />
              <el-option label="次月生效" :value="2" />
            </el-select>
          </td>
        </tr>

        <tr>
          <td>补缴</td>
          <td>
            <el-input v-model="form.insurancePayBackEndDate" placeholder="截止日期。示例:15" />
          </td>
          <td>
            <el-select v-model="form.insurancePayBackMonth" placeholder="生效方式">
              <el-option label="不允许补缴" :value="0" />
              <el-option label="允许补缴前1个月" :value="1" />
              <el-option label="允许补缴前2个月" :value="2" />
              <el-option label="允许补缴前3个月" :value="3" />
              <el-option label="允许补缴前4个月" :value="4" />
              <el-option label="允许补缴前5个月" :value="5" />
              <el-option label="允许补缴前6个月" :value="6" />
              <el-option label="允许补缴前7个月" :value="7" />
              <el-option label="允许补缴前8个月" :value="8" />
              <el-option label="允许补缴前9个月" :value="9" />
              <el-option label="允许补缴前10个月" :value="10" />
              <el-option label="允许补缴前11个月" :value="11" />
              <el-option label="允许补缴前12个月" :value="12" />
            </el-select>
          </td>

          <td>补缴</td>
          <td>
            <el-input v-model="form.fundPayBackEndDate" placeholder="截止日期。示例:15" />
          </td>
          <td>
            <el-select v-model="form.fundPayBackMonth" placeholder="生效方式">
              <el-option label="不允许补缴" :value="0" />
              <el-option label="允许补缴前1个月" :value="1" />
              <el-option label="允许补缴前2个月" :value="2" />
              <el-option label="允许补缴前3个月" :value="3" />
              <el-option label="允许补缴前4个月" :value="4" />
              <el-option label="允许补缴前5个月" :value="5" />
              <el-option label="允许补缴前6个月" :value="6" />
              <el-option label="允许补缴前7个月" :value="7" />
              <el-option label="允许补缴前8个月" :value="8" />
              <el-option label="允许补缴前9个月" :value="9" />
              <el-option label="允许补缴前10个月" :value="10" />
              <el-option label="允许补缴前11个月" :value="11" />
              <el-option label="允许补缴前12个月" :value="12" />
            </el-select>
          </td>
        </tr>
      </tbody>
    </table>

    <div>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>

import {
  insurancefundList,
  insurancefundSave,
  insurancefundUpdate,
  insurancefundDelete,
  insurancefundInfo
} from '@/api/insurancefund';

import { listCityRegions, } from '@/api/comm';

export default {

  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      cityList: [],

      // 表单参数
      form: {
        diffFlag: 2,
        largeMedicalPayType: 1,
        insuranceEnterValidType: 1,
        fundEnterValidType: 1,
        insuranceLeaveValidType: 1,
        fundLeaveValidType: 1,
        insuranceChangeEnterValidType: 1,
        fundChangeEnterValidType: 1,
        insurancePayBackMonth: 0,
        fundPayBackMonth: 0
      },
      // 表单校验
      rules: {
        positionName: [
          {
            required: true,
            message: '职位名称不能为空',
            trigger: 'blur'
          },
        ],
        type: [
          {
            required: true,
            message: '招聘形式不能为空',
            trigger: 'change'
          },
        ],
      }
    };
  },
  created() {
    const { id } = this.$route.params;
    if (this.$route.params.id !== '-1') {
      setTimeout(() => {
        document.title = '编辑参保城市';
        this.handleUpdate(id);
      }, 800);
    } else {
      setTimeout(() => {
        document.title = '新增参保城市';
        this.handleAdd();
      }, 800);
    }

    // this.getList();
    this.getCityList();
  },
  methods: {
    getCityList() {
      listCityRegions()
        .then(({ data }) => {
          this.cityList = data;
        });
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      insurancefundList(queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.$router.back();
    },
    // 表单重置
    reset() {
      this.form = {
        diffFlag: 2,
        largeMedicalPayType: 1,
        insuranceEnterValidType: 1,
        fundEnterValidType: 1,
        insuranceLeaveValidType: 1,
        fundLeaveValidType: 1,
        insuranceChangeEnterValidType: 1,
        fundChangeEnterValidType: 1,
        insurancePayBackMonth: 0,
        fundPayBackMonth: 0
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增信息';
      if (this.cityList.length > 0) {
        this.form.cityRegion = this.cityList[0].key;
      }
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset();
      insurancefundInfo(id)
        .then((response) => {
          this.form = {
            ...response.data,
            cityRegion: `${response.data.cityId}-${response.data.regionId}`
          };
          this.open = true;
          this.title = '修改信息';
        });
    },
    enableItem(row) {
      insurancefundUpdate({
        id: row.id,
        positionStatus: row.positionStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        this.form.cityId = this.form.cityRegion.split('-')[0];
        this.form.regionId = this.form.cityRegion.split('-')[1];
        if (valid) {
          if (this.form.id != undefined) {
            insurancefundUpdate(this.form)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.$router.back();
                // this.open = false;
                // this.getList();
              });
          } else {
            insurancefundSave(this.form)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.$router.back();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => insurancefundDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>

<style lang="less" scoped>
.online-info-page {
  table {
    td {
      padding: 10px 5px;
      //margin:0 5px;
    }
  }

  .dialog-footer {
    width: 200px;
    margin: 10px auto;
  }

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
